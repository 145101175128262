html {
    font-size: .9rem;
}

@media (min-width : 600px) {
    html{
        font-size: 1rem;
    }
}

body {
	margin: 0;
	// font-family: 'Nunito', sans-serif;
}

img{
    max-width: 100%;
}

*{
	box-sizing: border-box;
}

/*  Layout  */


.arena-container {
	box-sizing: border-box;
	font-family: 'Poppins';
}

.auth-container {
	box-sizing: border-box;
	height: 100vh;
	font-family: 'Poppins';
	background: linear-gradient(0deg, #4062CD, #4062CD);
}

.header-container {
	height: 20vh;
	padding: 50px 0px;
	width: 95%;
	margin:auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-info{
    font-weight: 700;
    color: #FFFFFF;
}

.header-username{
    font-size: 0.875rem;
    margin: 0;
}

.header-balance{
    font-size: .80rem;
}

.header-point{
    font-size: 0.6875rem;
    color: #41F502;
    cursor: pointer;
}

@media (max-width: 576px) {
    .header-logo{
        width: 50%;
    }
    .auth-title{
        font-size: 1.2rem !important;
    }
    .auth-subtitle{
        font-size: 0.9rem !important;
    }
    .register-guest-btn{
        margin-top: 1em;
    }
}

@media (min-width : 576px) {
    .arena-signin-img {
        padding-left: 5em;
    }
    .login-form {
        max-width: 450px;
    }
    .register-btn-width {
        width: 438px;
    }
    .register-form{
        max-width: 600px;
    }
}

.img-container {
	height: 100%;
}

/* Typography */

.auth-title{
	font-style: normal;
	font-weight: 800;
	font-size: 1.5rem;
	line-height: 25px;  
	text-transform: uppercase;
	letter-spacing: -0.019em;
	color: #FFFFFF;
	text-align: center;
}

.auth-subtitle {
	font-style: normal;
	font-weight: 800;
	font-size: 1.25rem;
	text-transform: uppercase;
	color: #FFFFFF;
	text-align: center;
}

// .auth-label {
// 	font-style: normal;
// 	font-weight: 800;
// 	font-size: 1rem;
// 	line-height: 21px;
// 	color: #666666;
// 	text-transform: uppercase;
// }

.register-text {
	padding-top: 3em;
}

.arena-signin-img{
	// width: 50%;
}
.black-bold-tx {
	font-weight: 700;
	font-size: 1.875rem;
	color: #000000;
}

.orange-bold-tx {
	font-weight: 700;
	font-size: 1rem;
	line-height: 32px;
	color: #E66121;
}

.register-text>P {
	font-style: normal;
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 30px;
	text-transform: capitalize;
	color: #FFFFFF;
}

.form-input {
	background: #FBFBFB;
	border: 1px solid #E1E1E1;
	border-radius: 4px;
	font-weight: 500;
	font-size: 0.8rem;
	color: #999999;
}

.login-guest-btn {
	width: 209.05px;
	border: 2px solid #FFFFFF;
	background: #4062CD;
	color: #FFFFFF;
}

/*  Landing Page */

/* Typography */
.landing-tx {
	font-weight: 700;
	font-size: 1rem;
	line-height: 24px;
	color: #FFFFFF;
	margin-bottom: .3rem
}

.landing-subtitle {
	font-weight: 300;
	font-size: 1rem;
	line-height: 24px;
	color: #FFFFFF;
}

.landing-title {
	font-style: normal;
	font-weight: 700;
	font-size: 2rem;
	line-height: 32px;
	letter-spacing: -0.019em;
	text-transform: capitalize;
	color: #FFFFFF;
}


/* landing layout */

.landing-container {
	font-family: 'Poppins';
	box-sizing: border-box;
	background: #0E3556;
	border: 1px solid #C8D4DB;
}

.oust-container {
	box-sizing: border-box;
	background: #004560;
	font-family: 'Poppins';
}

.play-container {
	box-sizing: border-box;
	background: #020054;
	font-family: 'Poppins';
}

.landing-main-container {
	min-height: 60vh;
}

.landing-footer {
	min-height: 20vh;
	background: #FFFFFF;
	border: 1px solid #C8D4DB;
}

.landing-small-btn {
	padding: .3em 2.5em !important;
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
	background: #0E3556;
}

.landing1-img {
	max-width: 90%;
}

.landing-tx-container {
	max-width: 700px;
	text-align: justify;
}

@media (max-width: 576px) {
    .landing1-img{
        margin-bottom: 1em;
    }
    
    .landing-main{
        padding-top: 1em;
    }

    .landing-button{
        text-align: center;
    }
    // .login-btn {
    //     padding: 0 30px !important;
    // }
    .outline-btn {
        padding: 0.3em 1em !important;
    }
    .login-guest-btn{
        width: 150px;
    }
}
    
@media (min-width: 576px) { 
    .landing-main {
        max-width: 85%;
    }
  
    // .login-btn {
    //     width: 210px;
    // }
}

/* Map now layout */

.form-lable {
	font-weight: 800;
	font-size: 2.25rem;
	// color: #3A54A5;
	color: #000000;
}

.form-option {
	font-weight: 800 !important;
	font-size: 1.5rem;
	// color: #3A54A5;
	color: #055863;
	margin: 0.5em;
}

// .radio-form-option {
// 	border: 3px solid #87AABD;
// 	border-radius: 4px;
//     margin-top: 1.5rem !important;
// }

.map-checkbox .form-check-input {
	background: #FFFFFF;
	border: 1px solid #8A8E99;
}

.map-checkbox .form-check-input:checked {
	border: 1px solid #8A8E99 !important;
	background: #F83269;
}

.checkbox-text {
	background: #DCE7ED;
	border: 1px solid #87AABD;
	font-weight: 700;
	font-size: 1rem;
}

/* Range */

.range-lable {
	font-weight: 700;
	font-size: 1.5rem;
	// line-height: 36px;
	color: #3A54A5;
}

.range-txt {
	padding: 3px 5px;
	font-weight: 700;
	font-size: 1.5rem;
	color: #3A54A5;
}

.custom-range input[type="range"]::-webkit-slider-thumb {
	background: #3A54A5 !important;
}

/* All the same stuff for Firefox */
.custom-range input[type="range"]::-moz-range-thumb {
	background: #3A54A5 !important;
}

/* All the same stuff for IE */
.custom-range input[type="range"]::-ms-thumb {
	background: #3A54A5 !important;
}

.custom-range .range-slider__tooltip__label {
	color: #3A54A5 !important;
	font-weight: 700 !important;
	font-size: 1rem !important;
	background-color: #DCE7ED !important;
}

.custom-range .range-slider__tooltip__caret {
	background-color: transparent !important;
}

.custom-range .range-slider__tooltip__caret::before {
	content: none !important;
}


.map-landing-main h2 {
	color: #2AA000;
	font-weight: 700;
	font-size: 2rem;
	font-family: 'Poppins'
}

.map-landing-subtitle {
	border: 3px solid #87AABD;
	border-radius: 20px;
	width: 70%;
}

.org-txt {
	font-weight: 700;
	font-size: 2rem;
	color: #E66121;
}

.map-content {
	font-weight: 500;
	font-size: 1.125rem;
	line-height: 27px;
	color: #000000;
	text-align: justify;
}

/* Buttons */

.fill-btn {
	padding: .3em 3em;
	border-radius: 4px;
	font-style: normal;
	font-weight: 700;
	font-size: 1rem;
	line-height: 32px;
	letter-spacing: -0.019em;
}

.yellow-btn {
	background: #FCA600;
	border: 1px solid #D48505;
	color: #FFFFFF;
}

.orange-btn {
	padding: .3em 3em;
	background: #E66121;
	border: 1px solid #C64E15;
	border-radius: 4px;
	font-style: normal;
	font-weight: 700;
	font-size: 1rem;
	line-height: 32px;
	color: #FFFFFF;
	letter-spacing: -0.019em;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.outline-btn {
	padding: .3em 3em;
	border-radius: 4px;
	font-weight: 700;
	letter-spacing: -0.019em;
	font-size: 1rem;
	line-height: 32px;
}

.outline-org-btn {
	border: 2px solid #E66121;
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
	color: #E66121;
}

.gray-btn {
	background: #707070;
	font-weight: 700;
	color: #FFFFFF;
}

.blue-btn {
	width: 215px;
	height: 44px;
	background: #3A54A5;
	border: 1px solid #293D7B;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	font-size: 1rem;
	color: #FFFFFF;
}

.dark-blue-btn{
    background: #173DC4;
    border: 1px solid #05185C;
    border-radius: 4px;
    color: #FFFFFF;
}

.outline-blue-btn {
	padding: .3em 3em;
	background: #DCE7ED;
	border: 2px solid #3A54A5;
	border-radius: 4px;
	font-weight: 700;
	letter-spacing: -0.019em;
	line-height: 32px;
	color: #3A54A5;
	font-size: 1.125rem;
}

.footer-outline-blue-btn {
	padding: .3em 3em;
	background: #FFFFFF;
	border: 2px solid #3A54A5;
	border-radius: 4px;
	font-weight: 700;
	letter-spacing: -0.019em;
	line-height: 32px;
	color: #3A54A5;
	font-size: 1.125rem;
}

.skip-btn {
	width: 200px;
	height: 44px;
	background: #707070;
	border-radius: 4px;
	font-style: normal;
	font-weight: 700;
	font-size: 1.25rem;
	color: #FFFFFF;
	line-height: 30px;
}

.map-outline-blue-btn-active {
	background: #0E3556;
	color: #FFFFFF;
}

.oust-outline-blue-btn-active {
	background: #004560;
	color: #FFFFFF;
}

.play-outline-blue-btn-active {
	background: #020054;
	color: #FFFFFF;
}

/* Map Container */

@media (max-width: 576px) {
	
    /*  Map Typography */

    .form-lable {
        font-size: 1.125rem;
        margin: 1rem 0.75rem 0 0.75rem;
    }

    .radio-form-option {
        margin-top: 0.75rem !important;
    }
    
    .form-option,
    .range-lable,
    .range-txt{
        font-size: 1rem;
    }

    .black-bold-tx {
        font-size: 1.125rem;
    }
    .custom-range .range-slider__tooltip__label {
        font-size: 1rem;
    }

    .range-margin:last-child {
        padding-bottom: 3em;
    }

    .map-landing-subtitle {
        width: 100%;
    }
}

@media (min-width: 576px) {

    // .question-border {
    //     border: 1px solid #87AABD;
    //     border-radius: 4px;
    //     padding: 2.25rem 4.5rem;
    // }

    .map-landing-main {
        max-width: 85%;
    }

}

.question-container {
	width: 95%;
	margin: auto;
	height: 80vh;
	box-sizing: border-box;
}
 
.map-container {
	background-color: #DCE7ED;
	font-family: 'Poppins' !important;
	box-sizing: border-box;
}


/*  Map Typography */
.map-tx {
	font-weight: 700;
	letter-spacing: -0.019em;
	color: #000000;
}

.won-coin {
	color: #E66121;
}

.question-container h1 {
	font-size: 2rem;
}

// .question-border {
// 	border: 1px solid #87AABD;
// 	border-radius: 4px;
// }

/*  Modal Typography */

.model-title-tx {
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 30px;
}

.model-main-tx {
	font-weight: 400;
	font-size: 1.125rem;
	line-height: 27px;
}

.rule-title {
	color: #F2AF08;
	padding: 0;
	padding-bottom: .5em;
}

.tx-yellow {
	color: #F2AF08;
}

.tx-red{
	color: #fa6767 !important;
}

.tx-dark-yellow {
	color: #FF9400;
}

.details-img {
	max-width: 90%;
	padding-top: 1em;
}

.outs-details-title {
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 30px;
	color: #FFFFFF;
}

.outs-details-subtitle {
	font-weight: 500;
	font-size: 1.125rem;
	line-height: 27px;
	color: #FFFFFF;
}

.outs-details-subtitle li::marker {
	color: #F2AF08;
	font-size: 1.5rem;
}

.modal-close-btn {
	position: absolute;
	top: 10px;
	right: 20px;
	color: #FFFFFF;
}

.modal-content {
	-webkit-border-radius: 10px !important;
	-moz-border-radius: 10px !important;
	border-radius: 10px !important;
}

.blue-modal {
	background: rgba(39, 66, 131, 0.97) !important;
	border: 1px solid #F5F5F5 !important;
	color: #FFFFFF !important;
}
.dark-blue-modal{
    background: #122B79 !important;
    border: 1px solid #F5F5F5 !important;
    color: #FFFFFF !important;
}

.yellow-modal {
	background: #653E00 !important;
	border: 1px solid #F5F5F5 !important;
	border-radius: 10px !important;
}

.redeem-btn {
	padding: .3em 3em .3em 1em !important;
}

.boy-img-container {
	width: 250px;
	height: 330px;
}

.extra-discount-container {
	width: 300px;
}

.extra-discount-container h1 {
	font-weight: 700;
	font-size: 1.5rem;
	line-height: 32px;
}

.extra-discount-container p {
	font-weight: 400;
	font-size: 1rem;
	line-height: 24px;
}

.extra-discount-container button {
	margin-top: 2em;
}

.boy-img {
	width: 100%;
}

.custom-carousel {
	position: initial;
}

/* Modal 
/* Common */
.ht-100 {
	height: 100%;
}

.tx-075 {
	font-size: 0.75rem;
}

.tx-14 {
	font-size: 0.90rem;
}

.tx-12{
	font-size: 0.75rem;
}

.tx-16{
    font-size: 1rem;
}

.mg-left-015 {
	margin-left: 1.5em
}

.mg-top-15 {
	margin-top: 1.5em;
}

.ht-36 {
	height: 36px;
}

.ht-50 {
	height: 50px;
}

.tx-center{
    text-align: center;
}

.w-70{
	width: 70%;
}
.modal .carousel-inner .item {
	z-index: 0
}

.redeem-coupon {
	padding-left: 2em;
}

.redeem-coupon h3 {
	padding-top: 1em;
	font-weight: 700;
	font-size: 1.5rem;
	letter-spacing: -0.019em;
}

.tx-16{
    font-size: 1rem;
}

.tx-Pointer{
	cursor: pointer;
}

.ht-100vh{
	height: 100vh;
}
.redeem-coupon h1 {
	font-weight: 700;
	font-size: 64px;
	line-height: 32px;
	letter-spacing: -0.019em;
	padding: 0.3em 0 0 0.2em;
}

/* Tenzi */
.tenzi-container {
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #0E3556;
	font-family: 'Poppins';
}

.tenzi-main {
	position: relative;
	background-color: #F5F5F5;
	width: 600px;
	height: 500px;
	border-radius: 5px;
	margin: auto;
	display: flex;
	justify-content: center;
	text-align: center;
	flex-direction: column;
    margin: 1em;
}

.die-container {
	display: grid;
	grid-template-columns: auto auto auto auto auto;
	padding: 30px 20px;
	gap: 18px;
	justify-content: center;
	align-items: center;
	align-content: center;
}

.die {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	width: 50px;
	background-color: #FFFFFF;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
	border-radius: 3.81277px;
	font-style: normal;
	font-weight: 700;
	font-size: 20.5889px;
}

.roll-button {
	margin: 0px auto;
	border: 1px solid #293D7B;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
	padding: 0.7em 3em;
	font-weight: 700;
	font-style: normal;
	color: #FFFFFF;
	background: #4768CD;
}

.tenzi-title {
	font-size: 40px;
	margin: 0;
	font-weight: 700;
	color: #FF9400;
}

.roll-text {
	position: absolute;
	bottom: 0px;
	right: 0px;
	padding: 2em;
	font-family: emoji;
	font-weight: bold;
}

.tenzi-close {
	position: absolute;
	top: 0;
	right: 10px;
	cursor: pointer;
}

/* Memory Magic */
.magic-card-container {
	height: 100vh;
	background-color: #1F152A;
}

.magic-card-sub-container {
	max-width: 700px;
	margin: auto;
	text-align: center;
    padding: 1em;
}

.magic-card-sub-container h1 {
	color: #F5F5F5;
	margin: 0;
}

.magic-card-sub-container p {
	font-style: normal;
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 30px;
	color: #FFFFFF;
}

.magic-card-gird {
	margin-top: 10px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-gap: 20px;
}

.magic-card {
	position: relative;
}

.magic-card img {
	width: 100%;
	display: block;
	border: 2px solid #FFFFFF;
	border-radius: 6px;
}

.magic-card .magic-front {
	transform: rotateY(90deg);
	transition: all ease-in 0.2s;
	position: absolute;
}

.flipped .magic-front {
	transform: rotateY(0deg);
	transition-delay: 0.2s;
}

.magic-card .magic-back {
	transition: all ease-in 0.2s;
	transition-delay: 0.2s;
}

.flipped .magic-back {
	transform: rotateY(90deg);
	transition-delay: 0s;
}

.magic-button {
	background: none;
	border: 2px solid #fff;
	padding: 6px 12px;
	border-radius: 4px;
	color: #fff;
	font-weight: bold;
	cursor: pointer;
	font-size: 1em;
	margin: 10px;
}

.magic-button:hover {
	background: #c23866;
	color: #fff;
}

// Quiz Game

@media (min-width: 576px) {
    .quiz-sub-container{    
        width: 750px;
        margin: auto;
    }

    .quiz-container{
        padding: 50px !important;
    }
}

.quiz-container{
    background: #F5F7FB;
    height: 100vh;
    font-family: 'Poppins';
    padding: 10px;
}

.quiz-sub-container i{
    color: #293264;
}

.quiz-title {
	font-style: normal;
	font-weight: 700;
	font-size: 2rem;
    color: #293264;
    margin: auto;
}

.quiz-question {
	font-style: normal;
	font-weight: 700;
	font-size: 1rem;
	line-height: 19px;
	color: #293264;
	line-height: 19px;
}

.quiz-answer {
	padding-top: 12px;
}

.quiz-answer span {
    margin-top: 5px;
	margin-right: 12px;
	border: 0.794239px solid #4D5B9E;
	border-radius: 7.94239px;
	padding: 4px 15px;
	font-style: normal;
	font-weight: 500;
	font-size: 0.75rem;
	color: #293264;
	white-space: nowrap;
    display: inline-block;
}

.quiz-line {
	margin: 15px 0px;
	border: 0.794239px solid #DBDEF0;
	transform: rotate(-0.05deg);
}

.quiz-button {
	padding: 10px;
	background: #4D5B9E;
	border-radius: 10px;
	font-style: normal;
	font-weight: 600;
	font-size: 0.75rem;
	color: #F5F7FB;
	margin-left: 10px;
}

.quiz-score{	
	font-style: normal;
	font-weight: 700;
	font-size: 1rem;
	line-height: 15px;
	text-align: center;
	color: #293264;
}

/* =================
Summary Layout
================= */

@media (min-width: 576px) {
    .summary-subcontainer{
        width: 90%;
    }
    .point-container{
        margin-left: 1em;
    }
    .point-container:first-child{
        margin-left: 0;
    }
}

.rewards-program{
    background: #304EAE;
    border-radius: 10px;
    padding: 1.25em 1.875em;
    color: #FFFFFF;
    font-weight: 500;
    width: 330px;
}
.summary-container{
    box-sizing: border-box;
	height: 100vh;
	font-family: 'Poppins';
	background: #1E2E61;
    // font-weight: 700;
}

.summary-subcontainer{
    font-weight: 700;
    text-transform: capitalize;
    color: #FFFFFF;
}

.summary-divider{
    border: 1px solid #92A6B1;
}

// .score-container{
//     padding-right: 36px;
//     border-right: 1px solid #92A6B1;
// }

/* =================
    Typography
================= */

.tx-20{
    font-size: 1.25rem;
}

.summary-subtitle{
    font-weight: 400;
    font-size: 0.875rem;
    color: #D0D8EF;
}

.earn-point{
    font-size: 1.375rem;
    color: #3CD905;
}

.score-title{
    font-size: 1.5rem;
    line-height: 36px;
}

.point-container{
    border: 2px solid #304EAE;
    border-radius: 10px;    
    padding: .5em 3em .5em 1em;
}

.point-title{
    font-weight: 500;
    font-size: 0.875rem;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-bottom: .3rem !important;
}
.point-sub-title{
    color: #B6C6FB;
    font-weight: 500;
}

.point-container p{
    margin: 0rem;
}

.total-score{
    line-height: 36px;
    color: #3CD905;
}

.history-btn{
    background: #132F92;
    border: 1px solid #4667CE;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    height: 24px;
    color: #FFFFFF;
    font-size: 0.75rem;
}
.history-line{
    border: 1px solid #92A6B1;
}

@media (max-width: 576px) {
	.coupon-terms{
		margin: 10px 0px !important;
	}
}
@media (min-width: 576px) {
	.coupon-title{
		margin: 0;
	}
	.redeem-subtitle{
		margin-top: 1em;
	}
}

.redeem-container{
	width: 95%;
	margin: auto;
	color: #FFFFFF;
}
.redeem-title{
	font-weight: 700;
	font-size: 1.5rem;
}
.redeem-subtitle{
	font-weight: 400;
	font-size: 0.875rem;
}
.redeem-point{
	color: #86C600;
}
.coupon-container{
	background: #273A78;
	border-radius: 8px;
	padding: 20px;
	margin: 10px 0px;
	position: relative;
}
.coupon-title{
	font-weight: 700;
	font-size: 1.25rem;
}
.coupon-condition{
	font-weight: 400;
	font-size: 0.75rem;
	color: #3CDBB5;
}
.coupon-terms{
	display: block;
	margin: 10px 30px;
	font-weight: 500;
	font-size: 0.75rem;
	color: #FF7431;
	text-decoration: underline;
	
}
.coupon-point{
	position: absolute;
	padding: .4em .7em;
	background: #1E48CB;
	border-radius: 8px;
	font-weight: 700;
	font-size: 0.625rem;
}
.coupon-expire{
	text-align: end;
	font-weight: 400;
	font-size: 0.75rem;
	color: #7183BD;
	margin-bottom: 0;
}

// Weight loss css



// .authentication-title{
// 	font-weight: 800;
// 	font-size: 2rem;
// 	text-align: center;
// 	text-transform: uppercase;
// }

// .authentication-subtitle{
// 	font-weight: 800;
// 	font-size: 1rem;
// 	text-align: center;
// 	text-transform: uppercase;
// }

.authentication-container{
	padding: 20px 20px 0 20px;
	min-height: 100vh;
}

// .child-div {
// 	height: 100%;
// 	// background-color: red;
// 	margin: 0 20px;
// 	display: flex;
// 	flex-direction: column;
// 	justify-content: center;
// 	align-items: center;
// }

// @media (min-width: 660px) {
// 	.child-div {
// 		background-color: #004560;
		
// 	}
// }
.authentication-link{
	font-style: normal;
	font-weight: 800 !important;
	font-size: 1rem;
	text-align: center;
	text-transform: uppercase;
	margin: 0;
}

.blue-button{
	padding: .5rem 1rem;
	background: #3A54A5;
	border: 1px solid #051C62;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 12px;
	font-style: normal;
	font-weight: 800;
	font-size: 1rem;
	color: #FFFFFF;
	text-transform: uppercase;
}

.map-marker{
	font-size: 40px;
	color: #DB8412;
}

.episode-container{
	display: flex;
	align-items: center;
	justify-content: center;
}

/* Episode Containt */

.episode{
	font-weight: 800;
	font-size: 1.1rem;
	color: #2E2E2E;
	margin-bottom: 3em;
}

.episode-title{
	font-size: 0.9rem;
	text-transform: uppercase;
	color: #868686;
}

.episode-text{
	font-weight: 800;
	font-size: 1rem;
	text-transform: uppercase;
	text-align: right;
	color: #055436;
	margin-bottom: 2em;
}

// .episide-image{
// 	width: 20px;
// }
.touch-point{
	margin-bottom: 35px;
}

.touch-point-container{
	height: 85vh;
}

.touch-point h4{
	font-style: normal;
	font-weight: 800;
	font-size: 1.2rem;
	// text-align: center;	
	text-transform: uppercase;
	color: #000000;
}

.touch-point-landing-container{
	display: flex;
	flex-direction: column;
	align-items: center;
}

.touch-point-landing{
	display: flex;
	flex-direction: column;
	align-items: center;
}

.touch-point-landing h2{
	font-size: 1.8rem;
}

.touch-point-landing h3{
	font-size: 1.5rem;
}

.touch-point-landing-img{
	width: 200px;
}

.complete-state{
	border: 2px solid #FB3BA2;
	border-radius: 20px;
	font-weight: 800;
	font-size: .8rem;
	padding: .1em .8em;
	text-transform: uppercase;
	color: #ED0276;
}

// Level 
.level-img{
	position: relative;
	width: 80px;
	margin-top: 30px;
}

.level-tx-container{
	position: absolute;
	top: 28px;
	left: 20px;
	font-weight: 800;
	font-size: 1rem;
	color: #FFFFFF;
}

.level-tx-container p{
	margin: 0;
}

// Quiz Layout

.quiz-image{
	width: 90%;
}

.quiz-radio .form-check-input {
	background: #FFFFFF;
	border: 2px solid #8AAFB4;
}

.quiz-radio .form-check-input:checked {
	background: #2F363C !important;
	border: 4px solid #FFFFFF !important;
}

.round-btn{
	padding: .7rem 4rem;
	background: #9252D1;
	border-radius: 40px;
	font-style: normal;
	font-weight: 800;
	font-size: 1rem;
	text-align: center;
	color: #FFFFFF;
	border: none;
}

// Quiz Landing

.quiz-landing{
	text-align: center;
	font-weight: 800;
}

.quiz-landing h2{
	margin: 0;
}

.quiz-landing h2{
	font-weight: 800;
	font-size: 2rem;
	color: #147E0E;
	margin-top: 1em;
}

.quiz-landing h2 + p{
	font-size: .8rem;
	color: #646464;
	margin-bottom: 2em;	
}

.quiz-landing h4{
	text-transform: uppercase;
	color: #000000;
	font-weight: 800;
	font-size: 1.5rem;
}

.quiz-landing h1{
	text-transform: uppercase;
	color: #000000;
	font-weight: 800;
	font-size: 2.5rem;
}

.score-msg{
	color: #00B671;
	font-size: 1rem;
}

.arena-footer{
	// background-color: lightblue;
	bottom: 0;
	margin: 0 auto;
	position: fixed;
	overflow: hidden;
	// width: 100%;
}

@media (max-width: 576px) {
	.display-none{
		display: none;
	}

	.auth-img{
		width: 75%;
	}

	.episode-container{
		display: block;
		// height: 80vh;
	}

	// .episode{
	// 	margin: 3em 0;
	// }

}
    
@media (min-width: 576px) { 
	.authentication-container{
		padding: 100px !important;
	}

	.authentication-link{
		padding: .7rem 1rem !important;
		font-size: 1.2rem !important;
	}

	.blue-button{
		// padding: .7rem 1rem !important;
		font-size: 1.2rem !important;
	}

	.episode{
		margin: 45px;
		text-align: center;
	}
	
	.episode:nth-child(odd) {
		padding-top: 0px;
	}
	
	.episode:nth-child(even) {
		padding-top: 120px;
	}

	.touch-point-landing{
		max-width: 500px;
	}
}